import React, { useState } from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "gatsby"
import * as qs from "query-string"

import jiggswapApi from "../api/jiggswap"
import PuzzleCardList from "components/puzzles/PuzzleCardList"

import AvatarImage from "../components/common/AvatarImage"

const UserPage = ({ location }) => {
  const [loadState, setLoadState] = useState("loading")
  const [profile, setProfile] = useState({})

  const isLoading = loadState === "loading"

  const query = qs.parse(location.search)
  const username = query.username

  useEffect(() => {
    const fetchData = async () => {
      var response = await jiggswapApi.get(`/userprofile/${username}`)

      setProfile(response.data)
      setLoadState("loaded")
    }
    fetchData()
  }, [username])

  return (
    <>
      <Helmet title={username + "'s profile - JiggSwap"}>
        <meta property="og:site_name" content={(username || "User Profile") + " - JiggSwap"} />
        <meta property="og:title" content={"View this user's collection on JiggSwap"} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={"https://www.jiggswap.com"} />
        <meta property="og:image" content={"https://www.jiggswap.com/logo.png"} />
        <meta property="og:description" content={"JiggSwap lets you trade your jigsaw puzzles with other puzzle lovers."} />
      </Helmet>
      <div className="site-content">
        <div className="has-text-centered">
          <figure className="image is-128x128" style={{ margin: "0 auto" }}>
            <AvatarImage imageUrl={profile.imageCdnUrl} />
          </figure>
          <div className="is-size-2">
            <span>{username}</span>
          </div>
          {isLoading && <>Loading {username}'s Profile...</>}
          <div>{profile.displayName}</div>
          <div>{profile.displayCity}</div>
          {profile.isCurrentUser && (
            <Link to="/profile" className="button is-primary" style={{ marginTop: "15px" }}>
              <i className="fas fa-user-edit mr-1"></i>Update Profile
            </Link>
          )}
        </div>
        <hr />
        {profile.isCurrentUser && (
          <div className="has-text-centered">
            <Link to="/puzzles/add" className="button is-primary">
              <i className="fas fa-plus mr-1"></i>Add a puzzle
            </Link>
            <hr />
          </div>
        )}
        <UserCollectionDisplay username={username} />
        {profile.isCurrentUser && (
          <div className="has-text-centered">
            <hr />
            <Link to="/puzzles/add" className="button is-primary">
              <i className="fas fa-plus mr-1"></i>Add a puzzle
            </Link>
            <hr />
          </div>
        )}
      </div>
    </>
  )
}

const UserCollectionDisplay = ({ username }) => {
  const [loadingState, setLoadingState] = useState("loading")

  const isLoading = loadingState === "loading"

  const [puzzles, setPuzzles] = useState([])

  useEffect(() => {
    const loadData = async () => {
      const response = await jiggswapApi.get(`/puzzles?owner=${username}`)

      setPuzzles(response.data)

      setLoadingState("loaded")
    }

    loadData()
  }, [username])

  return (
    <>
      {(isLoading || puzzles.length > 0) && (
        <div className="has-text-centered is-size-4">
          <span className="has-text-weight-semibold">{username}</span>'s puzzles:
        </div>
      )}
      {isLoading && (
        <div className="has-text-centered" style={{ marginTop: "3rem" }}>
          Loading puzzles...
        </div>
      )}
      {puzzles.length === 0 && (
        <div className="has-text-centered" style={{ marginTop: "3rem" }}>
          {username} has no puzzles in their collection.
        </div>
      )}

      <PuzzleCardList puzzles={puzzles} />
    </>
  )
}

export default UserPage
