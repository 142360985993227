import React from "react"

import placeholderImg from "images/avatar-placeholder.jpg"

const AvatarImage = ({ imageUrl }) => {
  let url = imageUrl

  if (!url) {
    url = placeholderImg
  }

  return <img className="is-rounded" src={url} alt="avatar" />
}
export default AvatarImage
